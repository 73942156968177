import React from "react"
import Navbar from "./Component/Navbar"
import Sosmed from "./Component/Sosmed"
import RouteList from "./RouteList"

const App = () => {
  return (
    <main className='w-full max-h-full overflow-auto main-bg main-text '>
      <Navbar />
      <Sosmed />
      <RouteList />
    </main>
  )
}

export default App
