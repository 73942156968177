import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const ProjectDetail = () => {
  const [Project, setProject] = useState({});
  const [technology, setTechnology] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    document.title = "Project Detail - Portfolio";
    const getProject = async () => {
      const request = await fetch("/project.json");
      const response = await request.json();
      const data = await response.projects.filter((project) => {
        if (project.id == params.id) {
          return project;
        }
      });
      setProject(data[0]);
      setTechnology(data[0].technology);
      setLoading(false);
    };
    getProject();
  }, []);

  return (
    <section className="px-6 md:px-4">
      <div className="max-w-5xl">
        {loading && <>Loading Data ....</>}
        <Link to="/project" className="flex">
          {<HiOutlineArrowNarrowLeft size={25} className="mr-2" />}{" "}
          <div>
            Back to <span className="text-pink-600 font-bold">Project</span>
          </div>
        </Link>
        <h2 className="text-lg md:text-3xl mb-4 border-b-4 border-pink-600 inline-block text-slate-600 dark:text-slate-200">{Project.title}</h2>
        {Project.image2 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-4 mb-2">
            <img src={Project.image} alt="project" className="block md:h-[300px] sm:w-4/6 md:w-full" />
            <img src={Project.image2} alt="project" className="block md:h-[300px] sm:w-4/6 md:w-full" />
          </div>
        ) : (
          <div className="flex justify-center mb-2">
            <img src={Project.image} alt="project" className="block sm:w-4/6" />
          </div>
        )}

        <p className="text-base md:text-lg dark:text-slate-300 text-slate-800">{Project.description}</p>
        <div className="flex flex-wrap justify-center gap-4 mt-6">
          {technology.map((tech, index) => {
            return (
              <div className="p-3 text-center rounded-md shadow-md border-2 border-slate-400 hover:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600" key={tech}>
                <div className="flex items-center justify-center mb-2">
                  <img src={tech} alt="Logo" className="h-[75px]" />
                </div>
                <p className="text-base font-semibold">{Project.tech_name[index]}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProjectDetail;
