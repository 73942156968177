import React from "react"
import { FaLinkedin, FaWhatsapp } from "react-icons/fa"
import { HiOutlineMail } from "react-icons/hi"
import { BsFillPersonLinesFill } from "react-icons/bs"

const Sosmed = () => {
  return (
    <div className='fixed left-0 flex-col hidden font-semibold lg:flex top-1/3 text-slate-50'>
      <ul>
        <li className='flex items-center justify-between h-10 px-2 -ml-24 duration-300 bg-blue-600 w-36 hover:ml-0'>
          <a href='https://www.linkedin.com/in/abdus-sakur-89918b1bb/' target='_blank' rel='noreferrer' className='flex items-center justify-between w-full'>
            LinkedIn <FaLinkedin size={30} />
          </a>
        </li>
        <li className='flex items-center justify-between h-10 px-2 -ml-24 duration-300 bg-green-600 w-36 hover:ml-0'>
          <a href='https://api.whatsapp.com/send/?phone=6281227560145' target='_blank' rel='noreferrer' className='flex items-center justify-between w-full'>
            Whatsapp <FaWhatsapp size={30} />
          </a>
        </li>
        <li className='flex items-center justify-between h-10 px-2 -ml-24 duration-300 bg-gray-600 w-36 hover:ml-0'>
          <a href='mailto:syakur17a@gmail.com' target='_blank' rel='noreferrer' className='flex items-center justify-between w-full'>
            E-mail <HiOutlineMail size={30} />
          </a>
        </li>
        <li className='flex items-center justify-between h-10 px-2 -ml-24 duration-300 w-36 hover:ml-0 bg-sky-600'>
          <a href='https://drive.google.com/file/d/1Z6JGjlEK-QncRUjx7vt9kY1B9zRULDWx/view' target='_blank' rel='noreferrer' className='flex items-center justify-between w-full'>
            Resume <BsFillPersonLinesFill size={30} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Sosmed
