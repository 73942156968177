import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { HiArrowNarrowRight } from "react-icons/hi"
import { FaWhatsapp, FaEnvelope } from "react-icons/fa"

const Home = () => {
  useEffect(() => {
    document.title = "Home - Portfolio"
  }, [])

  const [profile, setProfile] = useState(false)
  const profileChange = () => setProfile(!profile)
  return (
    <section>
      <div className='flex flex-col items-center justify-center gap-6 md:flex-row hsm:pb-8 main-bg'>
        <div className='cursor-pointer' onClick={profileChange}>
          {!profile ? (
            <img src='/images/batik.jpg' alt='myPhoto' className='w-[250px] h-[325px] rounded-lg shadow-md shadow-pink-500 hover:shadow-pink-700 dark:hover:shadow-pink-500' />
          ) : (
            <img src='/images/sakur2.jpg' alt='myPhoto' className='w-[250px] h-[325px] rounded-lg shadow-md shadow-pink-500 hover:shadow-pink-700 dark:hover:shadow-pink-500' />
          )}
        </div>

        <div className='md:text-left text-center px-3 sm:px-0 max-w-[500px] lg:max-w-[700px]'>
          {!profile ? (
            <>
              <p className='text-sm font-semibold text-pink-600 sm:text-lg'>Hi, my name is</p>
              <h2 className='text-slate-800 dark:text-slate-300 sm:pb-2'>Abdus Sakur</h2>
              <h4 className='text-slate-500 dark:text-slate-400 sm:pb-2'>I&apos;m a Full Stack Developer</h4>
              <p className='text-sm sm:text-base dark:text-slate-200'>
                I have 3 years work experience as <span className='font-bold text-pink-600 dark:text-pink-500'>fulltime</span> programmer. Usually, i work as{" "}
                <span className='font-bold text-pink-600 dark:text-pink-500'>back-end developer</span>, but i love too working as <span className='font-bold text-pink-600 dark:text-pink-500'>full-stack developer</span>. Currently, i&apos;m
                focused on building <span className='font-bold text-pink-600 dark:text-pink-500'>hospital web applications</span> at RSUP dr. Kariadi Semarang as full-stack developer.
              </p>
            </>
          ) : (
            <>
              <p className='text-sm font-semibold text-pink-600 sm:text-lg'>Hi, my name is</p>
              <h2 className='sm:pb-2 text-slate-800 dark:text-slate-300'>Abdus Sakur</h2>
              <h4 className='sm:pb-2 text-slate-500 dark:text-slate-400'>I&apos;m a Bachelor of Computer</h4>
              <p className='text-sm sm:text-base dark:text-slate-200'>
                I graduated from University Stikubank (Unisbank) Semarang in 2020, Faculty of <span className='font-bold text-pink-600 dark:text-pink-500'>Information Technology</span>. I took the thesis title &quot;
                <span className='italic'>Sistem Pencarian Data Service di IT Service dengan Metode Dice Similarity</span>&quot; with an <span className='font-bold text-pink-600 dark:text-pink-500'>A</span>. I graduated with a{" "}
                <span className='font-bold text-pink-600 dark:text-pink-500'>GPA</span> of <span className='font-bold text-pink-600 dark:text-pink-500'>3.66</span>.
              </p>
            </>
          )}
          <div className='flex items-center justify-center mt-4 text-gray-300 lg:hidden md:justify-start'>
            <a className='flex mb-1 mr-5 hover:text-green-600' href='https://api.whatsapp.com/send/?phone=6281227560145' target='_blank' rel='noreferrer'>
              <FaWhatsapp size={20} className='mr-1' /> 081227560145
            </a>
            <a className='flex hover:text-slate-500' href='mailto:syakur17a@gmail.com' target='_blank' rel='noreferrer'>
              <FaEnvelope size={20} className='mr-1' /> syakur17a@gmail.com
            </a>
          </div>
          <div className='flex justify-center mt-4 md:justify-start'>
            <Link to='/project' className='btn-my-work'>
              My Project <HiArrowNarrowRight className='ml-2' size={20} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
