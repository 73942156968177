import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Project from "./Pages/Project";
import ProjectDetail from "./Pages/ProjectDetail";
import Skill from "./Pages/Skill";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="project" element={<Project />} />
      <Route path="project-detail/:id" element={<ProjectDetail />} />
      <Route path="skill" element={<Skill />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  );
};

export default RouteList;
