import React, { useEffect, useState } from "react";

const Skill = () => {
  const [skills, setSkills] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "Skill - Portfolio";

    const getSkill = async () => {
      const request = await fetch("/skill.json");
      const response = await request.json();
      setSkills(response.Skills);
      setLoading(false);
    };
    getSkill();
  }, []);
  return (
    <section className="px-2 sm:px-0">
      <div className="max-w-5xl">
        <h3>Skill</h3>
        <p className="pt-4 pb-2">These are the technologies i&apos;v worked with</p>
        {loading ? (
          <div>Loading data....</div>
        ) : (
          <div className="grid grid-cols-3 lg:grid-cols-4 gap-4">
            {skills.map((skill) => {
              return (
                <div className="p-5 rounded-md border-2 sm:border-4 border-slate-500 dark:border-slate-400 shadow-slate-500 shadow-md text-center bg-slate-300 dark:bg-transparent" key={skill.image}>
                  <div className="flex justify-center items-center">
                    <img src={skill.image} alt="logo" className="h-[50px] sm:h-[100px]" />
                  </div>
                  <p className="font-bold mt-2 text-xs sm:text-base">{skill.name}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default Skill;
