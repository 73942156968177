import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const Project = () => {
  const [projects, setProjects] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    document.title = "Project - Portfolio"

    const getListProject = async () => {
      const request = await fetch("/project.json")
      const response = await request.json()
      setProjects(response.projects)
      setLoading(false)
    }
    getListProject()
  }, [])
  return (
    <section>
      <div className='max-w-5xl p-4 mb-12'>
        <div className='pb-4 '>
          <h3>Project</h3>
          <p className='pt-4'>Check out some of my project</p>
        </div>
        {loading ? (
          <div>Loading data ...</div>
        ) : (
          <div className='grid grid-cols-1 gap-4 overflow-y-auto h-[535px] px-2 sm:grid-cols-2 md:grid-cols-3'>
            {projects.map((project) => {
              return (
                <div key={project.id}>
                  <div style={{ backgroundImage: `url(${project.image})` }} className='group bg-project'>
                    <div className='px-3 font-bold text-center opacity-0 group-hover:opacity-100 text-slate-700'>
                      <p className='pb-8 text-xl tracking-wider'>{project.title}</p>
                      <div>
                        <Link to={`/project-detail/${project.id}`} className='px-3 py-2 mx-2 rounded-md bg-slate-600 text-slate-200'>
                          Detail
                        </Link>
                        {project.link && (
                          <a href={project.link} className='px-5 py-2 mx-2 rounded-md bg-slate-600 text-slate-200' target='_blank' rel='noreferrer'>
                            Visit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default Project
