import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [dark, setDark] = useState(true);
  const [mobile, setMobile] = useState(false);
  const handleClose = () => setMobile(!mobile);
  const changeDark = () => {
    setDark(!dark);
  };
  if (dark) {
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }
  return (
    <div className="fixed h-16 w-full flex justify-between items-center main-text main-bg font-bold px-6 z-50 top-0 ">
      {/* logo or something */}
      <Link to="/" className="w-20 flex flex-col justify-center items-center text-sm border-2 border-slate-500 dark:border-slate-200 py-1 px-2 font-bold">
        <div>
          <span className="border-b-2 border-slate-600 dark:border-slate-200">Clean</span> n&apos;
        </div>
        <div className="text-pink-600">Smooth</div>
      </Link>
      {/* menu  */}
      <div className="flex gap-6 items-center">
        <ul className="hidden md:flex md:gap-4">
          <NavLink to="/">
            <li className="link-underline">Home</li>
          </NavLink>
          <NavLink to="project">
            <li className="link-underline">Project</li>
          </NavLink>
          <NavLink to="skill">
            <li className="link-underline">Skill</li>
          </NavLink>
          <NavLink to="contact">
            <li className="link-underline">Contact</li>
          </NavLink>
        </ul>

        <ul className={!mobile ? "hidden" : "main-bg absolute w-full h-screen flex flex-col items-center pt-10 top-0 left-0"}>
          <NavLink onClick={handleClose} to="/">
            <li className={`link-underline ${!mobile ? "" : "py-3 text-lg"}`}>Home</li>
          </NavLink>
          <NavLink onClick={handleClose} to="project">
            <li className={`link-underline ${!mobile ? "" : "py-3 text-lg"}`}>Project</li>
          </NavLink>
          <NavLink onClick={handleClose} to="skill">
            <li className={`link-underline ${!mobile ? "" : "py-3 text-lg"}`}>Skill</li>
          </NavLink>
          <NavLink onClick={handleClose} to="contact">
            <li className={`link-underline ${!mobile ? "" : "py-3 text-lg"}`}>Contact</li>
          </NavLink>
        </ul>
        {/* menu mobile  */}
        <div onClick={handleClose} className="md:hidden z-10">
          {!mobile ? <FaBars /> : <FaTimes />}
        </div>
        <div className="cursor-pointer" onClick={changeDark}>
          {!dark ? <BsSunFill className="text-orange-500" size={20} /> : <BsMoonStarsFill size={20} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
