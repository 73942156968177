import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSend } from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import { sendForm } from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [send, setSend] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    setSend(true);
    sendForm("service_wjkjj2a", "template_63z99ul", e.target, "ml534dvAnxVmR2taw")
      .then((res) => {
        if (res.status) {
          setSend(false);
        }
        if (res.status == 200) {
          toast.success("email sent successfully 😍");
        } else {
          toast.error("email failed to sent 😢");
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    document.title = "Contact - Portfolio";
  }, []);
  return (
    <section>
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="w-full px-4 sm:px-0 sm:w-auto sm:max-w-5xl">
        <h3>Contact</h3>
        <p className="py-4">Let&apos;s collaborate 😁</p>
        <form onSubmit={handleSubmit(onSubmit)} className="sm:min-w-[500px]">
          <label htmlFor="name">
            <span className="font-semibold">Name</span>
            <input type="text" name="name" id="name" className="form-input " {...register("name", { required: true, minLength: 4 })} />
            {errors?.name?.type === "required" && <small className="error">The name is required</small>}
            {errors?.name?.type === "minLength" && <small className="error">Min 4 characters</small>}
          </label>
          <label htmlFor="email">
            <span className="font-semibold">E-mail</span>
            <input type="email" name="email" id="email" className="form-input " {...register("email", { required: true })} />
            {errors?.email?.type === "required" && <small className="error">The e-mail is required</small>}
          </label>
          <label htmlFor="message">
            <span className="font-semibold">Message</span>
            <textarea name="message" id="message" rows="7" className="form-input" {...register("message", { required: true })}></textarea>
            {errors?.message?.type === "required" && <small className="error">The message is required</small>}
          </label>
          <button className="btn-submit flex justify-center items-center">Send {!send ? <BiSend size={20} className="ml-2" /> : <CgSpinner size={20} className="ml-2 animate-spin" />}</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
